import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class ExhibitionLimitRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className={`${
              post.frontmatter.featuredpost ? 'featured-exhibition' : ''
            } is-parent column is-4 featured-list`} key={post.id}>
              <article
                className={`exhibition-list-item tile is-child box notification ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className={`${
                      post.frontmatter.featuredpost ? 'is-12' : ' is-10'
                    } featured-thumbnail column`}>
                      <Link
                        className="title has-text-primary is-size-4"
                        to={post.fields.slug}
                      >
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </Link>
                    </div>
                  ) : null}
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary is-size-4"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <span className="subtitle is-size-5 is-block">
                      {post.frontmatter.startdate} – {post.frontmatter.enddate}
                    </span>
                  </p>
                </header>
                <p>
                {post.excerpt} <Link className="keep-reading" to={post.fields.slug}>
                    Keep Reading →
                  </Link>
                  {/* 
                  <br />
                  <br />
                  <Link className="button" to={post.fields.slug}>
                    Keep Reading →
                  </Link> */}
                </p>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

ExhibitionLimitRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ExhibitionLimitRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "exhibition-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
                startdate(formatString: "MMM DD, YYYY")
                enddate(formatString: "MMM DD, YYYY")
                description
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 520, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ExhibitionLimitRoll data={data} count={count} />}
  />
)
